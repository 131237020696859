<template>
  <div class="w-full">
    <div class="flex items-center justify-between">
      <h2 class="text-4xl font-medium">
        {{ serviceInterval(interval) }}: {{ name }}
      </h2>
      <BaseButton color="green" @click="saveBlade">
        Save {{ serviceInterval(interval) }}
      </BaseButton>
    </div>
    <div class="w-full mt-6">
      <BaseInput v-model="name" name="name" :rules="rules('name')" :label="`${serviceInterval(interval)} name`"></BaseInput>
    </div>
    <div class="w-full">
      <BaseImage v-model="blade_image" name="blade_image"  :rules="rules('blade_image')" class="mt-12"></BaseImage>
    </div>
    <div class="w-full mt-12">
      <BaseInput v-model="blade_image_description" :showSymbols="true" :rules="rules('blade_image_description')" label="Photo description" name="description"></BaseInput>
    </div>
    <div class="w-full mt-12">
      <BaseInput class="block w-1/3 mb-6" v-model="vui" :showSymbols="true" :rules="rules('vui')" label="VUI" name="vui"></BaseInput>
      <BaseInput class="block w-1/3 mb-6" v-model="generic_item"  :showSymbols="true" :rules="rules('generic_item')" label="Generic Item" name="generic_item"></BaseInput>
      <BaseInput class="block w-1/3 mb-6" v-model="sn" :showSymbols="true" :rules="rules('sn')" label="S/N" name="sn"></BaseInput>
      <template v-if="serviceInterval(interval) === 'Blade'">
          <BaseInput class="block w-1/3 mb-6" v-model="item" :showSymbols="true" :rules="rules('item')" label="Blade ID" name="item"></BaseInput>
          <BaseInput class="block w-1/3 mb-6" type="number" :step="1000" v-model.number="blade_size" :showSymbols="false" :rules="rules('blade_size')" label="Blade Size (MM)" name="blade_size"></BaseInput>
      </template>
    </div>
    <div class="w-full mt-12">
      <DamageList></DamageList>
    </div>
  </div>
</template>

<script>
// Helpers
import { sync, get } from 'vuex-pathify'
import rules from '@/helpers/rules.js'
import uuid from '@/helpers/generateUuid.js'
import serviceInterval from '@/helpers/serviceInterval.js'

export default {
  computed: {
    interval: get('report@service_interval_id'),
    name: sync('blade@name'),
    blade: sync('blade'),
    blade_image: sync('blade@blade_image'),
    vui: sync('blade@vui'),
    item: sync('blade@item'),
    sn: sync('blade@sn'),
    blade_size: sync('blade@blade_size'),
    blade_image_description: sync('blade@blade_image_description'),
    generic_item: sync('blade@generic_item'),
    report: sync('report')
  },
  methods: {
    serviceInterval,
    rules: rules('blade'),
    saveBlade () {
      if (this.blade.id === undefined) {
        // Push to blade array if new
        this.blade.id = uuid()
        this.report.blades = [...this.report.blades, this.blade]
      } else {
        // Replace blade in array if edit
        this.report.blades = this.report.blades.map(blade => {
          if (blade.id === this.blade.id) {
            return this.blade
          }
          return blade
        })
      }
      this.blade = undefined
    }
  }
}
</script>
