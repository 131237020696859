import localforage from 'localforage'

const error = localforage.createInstance({
  name: 'error'
})

const store = (key, data) => {
  error.setItem(key, data)
  return data
}

const get = (key) => {
  return error.getItem(key)
}

const update = async (key, data) => {
  error.setItem(key, data)
  return data
}

const remove = async (key) => {
  return new Promise((resolve, reject) => {
    error.removeItem(key, (err, value) => resolve(value))
  })
}

const keys = async () => {
  return error.keys()
}

export default {
  get,
  store,
  update,
  remove,
  keys
}
