import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import Vue from 'vue'

import error from '@/database/models/errors.js'
import uuid from '@/helpers/generateUuid.js'

Bugsnag.start({
  appVersion: process.env.VERSION,
  apiKey: '438fede8e8c00e6d9340435e7671544a',
  plugins: [new BugsnagPluginVue()],
  releaseStage: process.env.NODE_ENV,
  onError: async (event) => {
    const vuex = JSON.parse(localStorage.getItem('vuex'))

    // Bind the currect user toe the event.
    if (vuex && vuex.auth && vuex.auth.user) {
      event.setUser(vuex.auth.user.display_name, null, vuex.auth.user.display_name)
    }

    if (vuex && vuex.report) {
      event.addMetadata('report', vuex.report)
    }

    // If the app is online, just send the error to bugsnag.
    if (navigator.onLine) {
      return true
    }

    // Store the event in the "error" IndexedDB table.
    await error.store(uuid(), event)

    // Returns false, so bugsnag won't try to send the error.
    return false
  }
})

const sendErrorsToBugsnag = async () => {
  const keys = await error.keys()

  // Loop though all the errors and send them to bugsnag
  keys.forEach(async key => {
    const event = await error.get(key)

    if (!navigator.onLine) {
      return
    }

    Bugsnag.notify(event.originalError)

    await error.remove(key)
  })
}

Bugsnag.getPlugin('vue')
  .installVueErrorHandler(Vue)

window.addEventListener('online', sendErrorsToBugsnag)
