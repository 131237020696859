<template>
    <div class="w-full">
        <div class="flex justify-between mb-12">
            <h2 class="text-3xl">
                Damage & Repair
            </h2>
            <BaseButton color="green" @click="addDamage">
                Add Damage
            </BaseButton>
        </div>
        <ul class="w-full damages">
            <Damage @in-view="changeInView" v-for="(damage, index) in damages" :key="index" :index="index" :damage="damage">
            </Damage>
        </ul>
    </div>
</template>

<script>
// Helpers
import { sync } from 'vuex-pathify'
import uuid from '@/helpers/generateUuid.js'
import rules from '@/helpers/rules.js'

// Config
import damagetypeOptions from '@/config/options/damagetype.js'

// Database
import DefaultDamage from '@/database/defaults/damage.js'
import save from '@/helpers/save.js'

export default {
    watch: {
        damages: 'observeNewListItems'
    },
    data() {
        return {
            observer: null
        }
    },
    mounted() {
        this.observer = new IntersectionObserver(this.changeInView, {
            root: document.querySelector('#container'),
            threshold: 0,
        });

        this.observeNewListItems();
    },
    computed: {
        damages: sync('blade@damages'),
        vuexReport: sync('report'),
        blade: sync('blade'),
        damagetypeOptions: () => damagetypeOptions
    },
    methods: {
        rules: rules('damage'),
        addDamage() {
            this.damages.push({
                ...DefaultDamage,
                images: [],
                id: uuid()
            })

            save(this.vuexReport, this.blade)
        },
        observeNewListItems() {
            Array.from(this.$el.querySelectorAll('.damages > li')).forEach((li) => this.observer.observe(li));
        },
        changeInView(entries, observer) {
            entries.forEach((entry) => {
                const id = entry.target.getAttribute('data-damage');

                this.damages = this.damages.map((damage, index) => {
                    if (damage.id === id) {
                        damage.inView = entry.isIntersecting;
                        return damage;
                    }

                    return damage;
                });
            })
        }
    }
}
</script>
